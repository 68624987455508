import React from "react";
import { Document, Page, Text, View, Image } from "@react-pdf/renderer";
import styles from "./PdfStyles";
import logo from "../../assets/images/main-logo-dark.png";
import mobileImage from "../../assets/images/mobile-image.png";
import shopping from "../../assets/images/shopping-bag.png";
import tag from "../../assets/images/tag.png";
import guitar from "../../assets/images/guitar.png";
import bell from "../../assets/images/bell.png";
import star from "../../assets/images/star.png";
import shop from "../../assets/images/shop.png";
import qr from "../../assets/images/qr-code.png";
import arrow from "../../assets/images/Arrow.png";
import startupIndia from "../../assets/images/startupIndia.png";
import footerLogo from "../../assets/images/footer-logo.png";
import storeLogo from "../../assets/images/store.png";

const PinPdfList = ({ generatedPins }) => {
  return (
    <Document>
      {generatedPins?.map((pin, index) => (
        <Page key={index} size="A4" style={styles.page}>
          <View style={styles.section}>
          <View style={styles.columns}>
            <View style={styles.column}>
              <Image style={styles.logo} src={logo} />
              <Text style={styles.fullPdfGreeting}>
                Dear Resident
              </Text>
              <Text style={styles.apartment}>
                House of Hiranandani
              </Text>
              <Text style={styles.text}>
                Welcome to Nivasi, a mobile app created to make community living
                seamless, available currently only to residents of the House of
                {"\n"}Hiranandani (HoH) in Egattur, Chennai.
              </Text>
              <Text style={styles.text}>
                In order to register as a resident on Nivasi app, please use the{" "}
                <Text style={styles.textHighlight}>
                  {" "}
                  resident verification code{" "}
                </Text>
                {"\n"}provided on the right.
              </Text>
              <Text style={styles.storeLogos}>
                <Image style={styles.appStore} src={storeLogo}></Image>
              </Text>
              <Text style={styles.pinText}>
                Complete your registration{"\n"}to unlock the app!
                {
                  "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"
                }
                <Image style={styles.arrow} src={arrow}></Image>
              </Text>
            </View>
            <View style={styles.pinColumn}>
              <Image style={styles.mobilimg} src={mobileImage}></Image>
              <Text style={styles.pinNo}>{pin}</Text>
            </View>
          </View>
            <View style={styles.info}>
              <View style={styles.columns}>
                <View style={styles.column}>
                  <View style={styles.title}>
                    <Image style={styles.shopping} src={shopping}></Image>
                    <Text style={styles.head}>Buy</Text>
                  </View>
                  <Text style={styles.para}>
                    Review pre-owned items listed for sale and contact sellers
                    in HoH.
                  </Text>
                </View>
                <View style={styles.column}>
                  <View style={styles.title}>
                    <Image style={styles.shopping} src={tag}></Image>
                    <Text style={styles.head}>Sell</Text>
                  </View>
                  <Text style={styles.para}>
                    List your pre-owned items for sale to fellow residents
                    within HoH.
                  </Text>
                </View>
                <View style={styles.column}>
                  <View style={styles.title}>
                    <Image style={styles.shopping} src={guitar}></Image>
                    <Text style={styles.head}>List your service</Text>
                  </View>
                  <Text style={styles.para}>
                    If you offer a service that can benefit the community,
                    please feel free to showcase it on the app.
                  </Text>
                </View>
              </View>
              <View style={styles.columns}>
                <View style={styles.column}>
                  <View style={styles.title}>
                    <Image style={styles.shopping} src={bell}></Image>
                    <Text style={styles.head}>Notifications</Text>
                  </View>
                  <Text style={styles.para}>
                    Setup notifications to get alerts for pre-owned items that
                    match your interest.
                  </Text>
                </View>
                <View style={styles.column}>
                  <View style={styles.title}>
                    <Image style={styles.shopping} src={shop}></Image>
                    <Text style={styles.head}>Refer service providers</Text>
                  </View>
                  <Text style={styles.para}>
                    Recommend high quality providers you've engaged with, so
                    fellow residents can choose with confidence.
                  </Text>
                </View>
                <View style={styles.column}>
                  <View style={styles.title}>
                    <Image style={styles.shopping} src={star}></Image>
                    <Text style={styles.head}>Review service quality</Text>
                  </View>
                  <Text style={styles.para}>
                    Help fellow residents make informed decisions by providing
                    reviews/ratings of service providers you engage with.
                  </Text>
                </View>
              </View>
              <View style={styles.line} />
              <Text style={styles.bulletPara}>
                • The app is free for residents to download and use.{"\n"}• We
                never share your contact information to service providers or
                other third parties for marketing purposes.{"\n"}• If you list
                your home based services on the app, it is important that you
                don't violate association by-laws.
              </Text>
            </View>
            <View style={styles.thanksMsg}>
              <Text style={styles.text}>
                Enjoy Nivasi! We'd love to know what you think. So, when you
                have a moment, share your feedback through the app and share
                suggestions on how we can improve.
              </Text>
              <Text style={styles.thanks}>Thank you,</Text>
              <Text style={styles.team}>Team Nivasi</Text>
            </View>
            <View style={styles.footerLine} />
            <View style={styles.footerColumns}>
              <View style={styles.footerColumn}>
                <Image style={styles.qr} src={qr}></Image>
              </View>
              <View style={styles.footerColumn}>
                <Text style={styles.footerContact}>
                  Questions? You can email us at{" "}
                  <Text style={styles.underline}> support@nivasiapp.com </Text>
                </Text>
                <Image style={styles.startupIndia} src={startupIndia}></Image>
                <Text style={styles.footerContact}>
                  Nivasi is a product developed by Ekam India Private Limited, a
                  Startup India recognized company.
                </Text>
              </View>
              <View style={styles.footerColumn}>
                <Image style={styles.footerLogo} src={footerLogo}></Image>
              </View>
            </View>
          </View>
        </Page>
      ))}
    </Document>
  );
};
export default PinPdfList;
