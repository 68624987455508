import React, { useState, useRef, useEffect } from "react";
import { useFormik, FormikProvider, Form } from "formik";
import * as Yup from "yup";
import {
  Button,
  Typography,
  Box,
  Modal,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";
import { TimeField } from "@mui/x-date-pickers/TimeField";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import { Close } from "@mui/icons-material";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const validationSchema = Yup.object().shape({
  schedule: Yup.object().shape(
    days.reduce((acc, day) => {
      acc[day] = Yup.array().of(
        Yup.object().shape({
          start: Yup.date().nullable().typeError("Invalid time format"),
          end: Yup.date()
            .nullable()
            .typeError("Invalid time format")
            .test("start-before-end", "Start time must be before end time", function (value) {
              return !this.parent.start || (value && dayjs(this.parent.start).isBefore(dayjs(value)));
            })
            .when("start", (start, schema) => (start && start.length > 0 && start[0] ? schema.required("End time is required if start time is set") : schema))
        })
      );
      return acc;
    }, {})
  ),
});


const ScheduleFormModal = ({ onCancelHandler, onOkHandler, formValues }) => {
  const open = useSelector((store) => store.common.operationalHoursModalOpen);
  const [copiedTiming, setCopiedTiming] = useState(null);
  const sectionRefs = useRef(days.reduce((acc, day) => ({ ...acc, [day]: React.createRef() }), {}));

  const [is24x7, setIs24x7] = useState(false);

  const handle24x7Change = (event) => {
    setIs24x7(event.target.checked);
    if (event.target.checked) {
      formik.setValues({
        schedule: days.reduce((acc, day) => ({ ...acc, [day]: [{ id: 1, start: null, end: null }] }), {}),
      });
    }
  };

  function reverseTransformSchedule(arrayData) {
    const schedule = days.reduce((acc, day) => {
      acc[day] = []; // Ensure every day is initialized as an empty array
      return acc;
    }, {});

    arrayData.forEach(({ day, startTime, endTime }) => {
      schedule[day].push({
        id: schedule[day].length + 1,
        start: startTime || null, // Ensure null if empty
        end: endTime || null, // Ensure null if empty
      });
    });

    // If a day has no entries, add a default entry with null values
    Object.keys(schedule).forEach(day => {
      if (schedule[day].length === 0) {
        schedule[day].push({ id: 1, start: null, end: null });
      }
    });

    return { schedule };
  }

  useEffect(() => {
    if (formValues) {
      if (formValues.length === 1 && formValues[0].day === "All") {
        setIs24x7(true);
        formik.setValues({
          schedule: days.reduce((acc, day) => ({ ...acc, [day]: [{ id: 1, start: null, end: null }] }), {}),
        });
      } else {
        setIs24x7(false);
        const transformedSchedule = reverseTransformSchedule(formValues);
        // Ensure the schedule exists before setting Formik values
        if (transformedSchedule.schedule) {
          formik.setValues((prevValues) => ({
            ...prevValues,
            schedule: transformedSchedule.schedule, // Patch the schedule safely
          }));
        }
      }
    }
  }, [formValues]);

  function transformSchedule(scheduleData) {
    return Object.entries(scheduleData.schedule).flatMap(([day, timings]) =>
      timings
        .filter(({ start, end }) => start && end) // Only include if both start and end exist
        .map(({ start, end }) => ({
          day,
          startTime: start,
          endTime: end
        }))
    );
  }

  const addTiming = (day) => {
    formik.setFieldValue(`schedule.${day}`, [
      ...formik.values.schedule[day],
      { id: formik.values.schedule[day].length + 1, start: null, end: null },
    ]);
  };

  const removeTiming = (day, index) => {
    const updatedTimings = formik.values.schedule[day].filter((_, i) => i !== index);
    formik.setFieldValue(`schedule.${day}`, updatedTimings);
  };

  const handleChange = (day, index, field, value) => {
    formik.setFieldValue(`schedule.${day}.${index}.${field}`, value ? dayjs(value) : null);
    formik.setFieldTouched(`schedule.${day}.${index}.${field}`, true, false);
  };

  const formik = useFormik({
    initialValues: {
      schedule: days.reduce((acc, day) => ({ ...acc, [day]: [{ id: 1, start: null, end: null }] }), {}),
    },
    validationSchema,
    onSubmit: (values) => {
      if (is24x7) {
        onOkHandler([{
          "day": "All",
          "startTime": null,
          "endTime": null
        }])
      } else {
        const transformedSchedule = transformSchedule(values);
        onOkHandler(transformedSchedule);
      }
    },
  });

  const copyTiming = (start, end) => {
    if (start && end) {
      setCopiedTiming({ start, end });
    }
  };

  const pasteTiming = (day, index) => {
    if (copiedTiming) {
      formik.setFieldValue(`schedule.${day}.${index}.start`, copiedTiming.start);
      formik.setFieldValue(`schedule.${day}.${index}.end`, copiedTiming.end);
      formik.setFieldTouched(`schedule.${day}.${index}.start`, true, false);
      formik.setFieldTouched(`schedule.${day}.${index}.end`, true, false);
    }
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Modal open={open} onClose={() => { onCancelHandler(); }}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              maxWidth: 700,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
              display: "flex",
              flexDirection: "column",
              height: "80vh",
              overflow: "hidden",
            }}
          >
            {/* HEADER */}
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", pb: 2, borderBottom: "1px solid #ccc" }}>
              <Typography variant="h6">Set Operational Hours</Typography>
              <IconButton onClick={onCancelHandler}>
                <Close />
              </IconButton>
            </Box>

            {/* 24/7 AVAILABILITY TOGGLE */}
            <FormControlLabel
              control={<Switch checked={is24x7} onChange={handle24x7Change} />}
              label="Available 24/7"
              sx={{ mt: 2, width: 'fit-content' }}
            />

            {/* MAIN CONTENT */}
            <Box sx={{ display: "flex", flex: 1, overflow: "hidden" }}>
              {/* Sidebar with day list */}
              <Box sx={{ width: "30%", bgcolor: "white", overflowY: "auto" }}>
                <List>
                  {days.map((day) => {
                    const isComplete = formik.values.schedule[day].some(
                      (timing) => timing.start && timing.end
                    );

                    return (
                      <ListItem
                        button
                        key={day}
                        onClick={() =>
                          sectionRefs.current[day]?.current?.scrollIntoView({ behavior: "smooth" })
                        }
                      >
                        <ListItemText primary={day} />
                        {isComplete && <CheckCircleIcon sx={{ color: "green", ml: 1 }} />}
                      </ListItem>
                    );
                  })}
                </List>
              </Box>

              {/* Form Content */}
              <Box sx={{ flex: 1, p: 3, overflowY: "auto" }}>
                <FormikProvider value={formik}>
                  <Form onSubmit={formik.handleSubmit} noValidate>
                    {days.map((day) => (
                      <Box key={day} ref={sectionRefs.current[day]} sx={{ mb: 3 }}>
                        <Typography variant="h5" gutterBottom>
                          {day}
                        </Typography>
                        {formik.values.schedule[day].map((timing, index) => (
                          <Box key={timing.id} sx={{ display: "flex", gap: 1, mb: 1 }}>
                            <TimeField
                              label="Start Time"
                              format="hh:mm A"
                              value={timing.start ? dayjs(timing.start) : null}
                              onChange={(value) => handleChange(day, index, "start", value)}
                              fullWidth
                              disabled={is24x7}
                              error={
                                formik.touched.schedule?.[day]?.[index]?.start &&
                                Boolean(formik.errors.schedule?.[day]?.[index]?.start)
                              }
                              helperText={
                                formik.touched.schedule?.[day]?.[index]?.start &&
                                formik.errors.schedule?.[day]?.[index]?.start
                              }
                            />
                            <TimeField
                              label="End Time"
                              format="hh:mm A"
                              value={timing.end ? dayjs(timing.end) : null}
                              onChange={(value) => handleChange(day, index, "end", value)}
                              fullWidth
                              disabled={is24x7}
                              error={
                                formik.touched.schedule?.[day]?.[index]?.end &&
                                Boolean(formik.errors.schedule?.[day]?.[index]?.end)
                              }
                              helperText={
                                formik.touched.schedule?.[day]?.[index]?.end &&
                                formik.errors.schedule?.[day]?.[index]?.end
                              }
                            />
                            <IconButton onClick={() => removeTiming(day, index)} disabled={is24x7}>
                              <DeleteIcon />
                            </IconButton>
                            <IconButton onClick={() => copyTiming(timing.start, timing.end)} disabled={is24x7}>
                              <ContentCopyIcon />
                            </IconButton>
                            <IconButton onClick={() => pasteTiming(day, index)} disabled={is24x7 || !copiedTiming}>
                              <ContentPasteIcon />
                            </IconButton>
                          </Box>
                        ))}
                        <Button startIcon={<AddIcon />} onClick={() => addTiming(day)} disabled={is24x7}>
                          More Timing
                        </Button>
                        <Divider sx={{ mt: 2, mb: 2 }} />
                      </Box>
                    ))}
                  </Form>
                </FormikProvider>
              </Box>
            </Box>

            {/* FOOTER */}
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", pt: 2, borderTop: "1px solid #ccc" }}>
              <Button variant="outlined" sx={{
                color: "#003350",
                border: "1px solid #003350",
              }} onClick={onCancelHandler}>
                Cancel
              </Button>
              <Button variant="contained"
                sx={{
                  textTransform: "capitalize",
                  bgcolor: "#003350",
                  color: "#fff",
                  "&:hover": {
                    bgcolor: "#0056b3",
                  },
                  borderRadius: "8px",
                  padding: "8px 16px",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
                type="button" onClick={formik.handleSubmit}>
                Save
              </Button>
            </Box>
          </Box>
        </Modal>

      </LocalizationProvider>

    </>
  );
};

export default ScheduleFormModal;
