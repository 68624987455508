import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
  Radio,
  RadioGroup,
  Tooltip,
  Snackbar,
  Alert,
} from "@mui/material";
import Loader from "../Common/Loader";
import { Link, useNavigate } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  AUTO,
  BLACK,
  BOLD,
  CENTER,
  COLUMN,
  DEEP_OCEAN_BLUE,
  FLEX,
  FLEX_START,
  NAME_PREFIX_TYPES,
  RED,
  SERVICE_LOCATION,
  SERVICE_PROVIDER_CREATE,
  SMALL,
  TEXT_COLOR,
} from "../../utils/constants";
import { Formik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { fetchAllServiceCategory } from "../../services/service-category";
import {
  openOperationalHoursModal,
  closeOperationalHoursModal,
  startLoading,
  stopLoading,
} from "../../redux/Slices/CommonSlice";
import { useDispatch } from "react-redux";
import { enqueueSnackbar } from "notistack";
import ImagePicker from "./ImagePicker";
import WorkHistoryOutlinedIcon from "@mui/icons-material/WorkHistoryOutlined";
import { fetchAllPreferredLanguage } from "../../services/preferred-language";
import { fetchAllResidentNames } from "../../services/resident";
import "./ServiceProvider.scss";
import "../Common/Common.scss";
import {
  addServiceProvider,
  fetchByBusinessName,
  fetchByPhoneNumber,
  uploadImage,
  fetchCountryCodes,
} from "../../services/service-provider";
import PhoneAutocomplete from "./PhoneAutocomplete";
import BusinessNameAutocomplete from "./BusinessNameAutocomplete";
import avatar from "../../assets/images/avatar.png";
import Validator from "./Validator";
import ScheduleFormModal from "./ScheduleFormModal";

const Create = (props) => {
  const breadcrumbs = [
    <Link
      key="1"
      color={DEEP_OCEAN_BLUE}
      to={"/admin/service-provider/all"}
      className="breadcrumb__link"
    >
      Service Provider
    </Link>,
    <Typography key="3" color={BLACK}>
      Add
    </Typography>,
  ];

  const dispatch = useDispatch();
  const [serviceCategories, setServiceCategories] = useState([]);
  const [countryCodes, setCountryCodes] = useState([]);
  const [preferredLanguage, setPreferredLanguage] = useState([]);
  const [residents, setResidents] = useState([]);
  const navigate = useNavigate();
  const [phoneData, setPhoneData] = useState([]);
  const [selectedPhoneData, setSelectedPhoneData] = useState(null);
  const [businessNameData, setBusinessNameData] = useState([]);
  const [selectedBusinessNameData, setSelectedBusinessNameData] =
    useState(null);
  const [queryParams, setQueryParams] = useState({});
  const [phoneError, setPhoneError] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState(`+91`);
  const [resides, setResides] = useState(false);
  const [spBusinessHours, setSpBusinessHours] = useState(null);
  const [priceErrors, setPriceErrors] = useState({});
  const [residentProviderValue, setResidentProviderValue] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertContent, setAlertContent] = useState("");

  let setFieldValueFunc = () => {};

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const params = {};
    for (const [key, value] of searchParams) {
      if (key === "referredBy" && value) {
        params[key] = Number(value);
      } else {
        params[key] = value;
      }
    }
    setQueryParams(params);

    const handlePhoneError = async (value) => {
      if (value) {
        const response = await fetchByPhoneNumber(value);
        if (response.success && response.data) {
          setPhoneError(true);
        }
      }
    };
    handlePhoneError(params?.phone);
  }, [residents]);

  const fetchServiceCategories = useCallback(async () => {
    dispatch(startLoading());
    const response = await fetchAllServiceCategory();
    if (response.success && response.data) {
      setServiceCategories(response?.data);
      dispatch(stopLoading());
    } else {
      dispatch(stopLoading());
      enqueueSnackbar(response.data, { variant: "error" });
    }
  }, [dispatch]);

  const fetchAllCountryCodes = useCallback(async () => {
    dispatch(startLoading());
    const response = await fetchCountryCodes();
    if (response.success && response.data) {
      setCountryCodes(response?.data);
      dispatch(stopLoading());
    } else {
      dispatch(stopLoading());
      enqueueSnackbar(response.data, { variant: "error" });
    }
  }, [dispatch]);

  const fetchPreferredLanguage = useCallback(async () => {
    dispatch(startLoading());
    const response = await fetchAllPreferredLanguage();
    if (response.success && response.data) {
      setPreferredLanguage(response?.data);
      dispatch(stopLoading());
    } else {
      dispatch(stopLoading());
      enqueueSnackbar(response.data, { variant: "error" });
    }
  }, [dispatch]);

  const fetchResidentNames = useCallback(async () => {
    dispatch(startLoading());
    const response = await fetchAllResidentNames();
    if (response.success && response.data) {
      setResidents(response?.data);
      const searchParams = new URLSearchParams(window.location.search);
      const params = {};
      for (const [key, value] of searchParams) {
        params[key] = value;
      }
      if (params?.referredBy) {
        const resident = response?.data.find(
          (resident) => resident.id === +params?.referredBy
        );
        if (resident) {
          setFieldValueFunc("referredBy", [resident?.id]);
        } else {
          setFieldValueFunc("referredBy", []);
        }
      }
      dispatch(stopLoading());
    } else {
      dispatch(stopLoading());
      enqueueSnackbar(response.data, { variant: "error" });
    }
  }, [dispatch]);

  useEffect(() => {
    fetchPreferredLanguage();
  }, [fetchPreferredLanguage]);

  useEffect(() => {
    fetchResidentNames();
  }, [fetchResidentNames]);

  useEffect(() => {
    fetchAllCountryCodes();
  }, [fetchAllCountryCodes]);

  useEffect(() => {
    fetchServiceCategories();
  }, [fetchServiceCategories]);

  useEffect(() => {
    if (serviceCategories) {
      setQueryParams((prev) => {
        if (prev?.serviceCategory) {
          const categoryServices = prev.serviceCategory
            .split(",")
            .map((item) => item.toLowerCase());
          const categories = serviceCategories
            .filter((category) =>
              categoryServices.includes(category.name.toLowerCase())
            )
            .map((matchedCategory) => matchedCategory.id);
          if (categories) {
            setFieldValueFunc("serviceCategory", categories);
            return { ...prev, serviceCategories: categories };
          }
        }
        return prev;
      });
    }
  }, [serviceCategories]);

  useEffect(() => {
    if (residents.length !== 0) {
      setQueryParams((prev) => {
        if (prev?.referredBy) {
          const matchedResident = residents.find(
            (resident) => resident.id === Number(prev.referredBy)
          );
          if (matchedResident) {
            setFieldValueFunc("referredBy", [matchedResident.id]);
            return { ...prev, referredBy: matchedResident.id };
          }
        }
        return prev;
      });
    }
  }, [residents]);

  const operationalHoursModalHanldler = () => {
    dispatch(openOperationalHoursModal());
  };

  const operationalHoursModalCloseHandler = () => {
    dispatch(closeOperationalHoursModal());
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
    setAlertContent("");
  };

  return (
    <Formik
      initialValues={{
        namePrefix: queryParams?.namePrefix || "",
        nameSuffix: queryParams?.nameSuffix || "",
        firstName: queryParams?.firstName || "",
        lastName: queryParams?.lastName || "",
        phone: queryParams?.phone || "",
        countryCode: queryParams.countryCode || "",
        email: queryParams?.email || "",
        title: queryParams?.title || "",
        businessName: queryParams?.businessName || "",
        serviceCategory: [],
        image: "",
        preferredLanguage: "",
        location: "",
        servicedTo: [],
        deliveryStatus: false,
        isResidentProvider: false,
        residentProvider: 0,
        homeBased: false,
        address: "",
        description: queryParams?.description || "",
        privateNotes: "",
        referredBy: queryParams?.referredBy || [],
        referAnonymously: false,
        referrerAvailedService: false,
        serviceLocation: "Premises",
        latitude: "",
        longitude: "",
        locationUrl: "",
        businessHours: [],
        serviceStartPrice: "",
        serviceEndPrice: "",
      }}
      enableReinitialize
      validationSchema={Validator(phoneData, phoneError)}
      onSubmit={async (values) => {
        const serviceLocationValues = values.serviceLocation;
        if (serviceLocationValues === "Premises") {
          values.serviceLocation = "Premises";
        } else if (serviceLocationValues === "Home") {
          values.serviceLocation = "HOME";
        } else {
          values.serviceLocation = serviceLocationValues;
        }

        values.doorDelivery =
          values.serviceLocation === "Premises"
            ? false
            : !values.deliveryStatus
            ? true
            : false;
        values.deliverable =
          values.serviceLocation === "Premises" ? false : true;
        values.homeVisit =
          values.serviceLocation === "Premises"
            ? false
            : values.deliveryStatus
            ? true
            : false;
        values.residentProvider =
          values.residentProvider && values.residentProvider !== 0
            ? values.residentProvider
            : null;
        const location = {
          formattedAddress: values.address,
          locationUrl: values.locationUrl,
          longitude: values.longitude,
          latitude: values.latitude,
          locality: values.address,
        };
        values.location = location;

        if (!values?.countryCode) {
          values = { ...values, countryCode: selectedCountryCode };
        }
        if (values.businessName.length === 0) {
          values.businessName = null;
        }
        if (values.email.length === 0) {
          values.email = null;
        }
        if (spBusinessHours) {
          values.businessHours = spBusinessHours;
        } else {
          values.businessHours = null;
        }
        values.serviceLocation = serviceLocationValues.toUpperCase();
        dispatch(startLoading());
        const result = await addServiceProvider(values);
        if (result.success) {
          if (values?.image) {
            const response = await uploadImage(
              values.image,
              result?.data?.data?.id
            );
            if (response.success) {
              dispatch(stopLoading());
              enqueueSnackbar(result?.data?.message, {
                variant: "success",
              });
              navigate(`/admin/service-provider/all`);
            } else {
              enqueueSnackbar(response?.data, { variant: "error" });
              navigate(`/admin/service-provider/all`);
            }
          } else {
            dispatch(stopLoading());
            enqueueSnackbar(result?.data?.message, {
              variant: "success",
            });
            navigate(`/admin/service-provider/all`);
          }
        } else {
          dispatch(stopLoading());
          enqueueSnackbar(result?.data, { variant: "error" });
        }
      }}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        setFieldValue,
        handleBlur,
        errors,
        touched,
        setFieldError,
      }) => {
        setFieldValueFunc = setFieldValue;
        const handleBusinessNameChange = async (value) => {
          setFieldValue("businessName", value);
          const response = await fetchByBusinessName(value);
          if (response.success && response.data) {
            setBusinessNameData(response.data);
          } else {
            setBusinessNameData([]);
          }
        };

        const validatePrices = (name, value) => {
          const newValues = { ...values, [name]: value };
          const startPrice = parseFloat(newValues.serviceStartPrice || 0);
          const endPrice = parseFloat(newValues.serviceEndPrice || 0);

          let newErrors = { ...priceErrors };

          if (startPrice && endPrice && startPrice >= endPrice) {
            newErrors = {
              ...newErrors,
              serviceStartPrice: "Start price must be less than end price.",
              serviceEndPrice: "End price must be greater than start price.",
            };
          } else {
            delete newErrors.serviceStartPrice;
            delete newErrors.serviceEndPrice;
          }

          setPriceErrors(newErrors);
        };

        const onCountryCodeChanged = (countryCode) => {
          setFieldValue("countryCode", countryCode.target.value);
          setSelectedCountryCode(countryCode.target.value);
        };

        const handleReferralHelper = (value, type) => {
          const result = value.target.checked;
          if (values?.referredBy?.length !== 0) {
            if (type === "referAnonymously") {
              setFieldValue("referAnonymously", result);
            } else {
              setFieldValue("referrerAvailedService", result);
            }
          } else {
            setAlertContent(
              "Please select a resident in the Referred By field to continue"
            );
            setAlertOpen(true);
          }
        };

        const handlePhoneNumberChange = async (value) => {
          setFieldValue("phone", value);
          if (value?.length === 10) {
            const response = await fetchByPhoneNumber(value);
            if (response.success && response.data) {
              setFieldError("phone", "Phone number already exist");
              setPhoneData([response.data]);
            } else if (response.data === "Service provider not found"){
              setFieldError("phone", "Phone number already exists, but in suspended status");
              setPhoneData([]);
            } else {
              setPhoneData([]);
              setFieldError("phone", "");
            }
          } else {
            setPhoneData([]);
            setFieldError("phone", "");
          }
        };

        const handleReferredBy = (newValue) => {
          if (newValue.length === 0) {
            setFieldValue("referAnonymously", false);
            setFieldValue("referrerAvailedService", false);
          }
          setFieldValue("referredBy", newValue);
        };

        const handleSelectResidentProvider = (newValue) => {
          setFieldValue("residentProvider", newValue);
          setResidentProviderValue(newValue);
        };

        const handleIsSelectResidentProvider = (newValue) => {
          if (newValue.target.value === "false") {
            setFieldValue("isResidentProvider", false);
            setFieldValue("homeBased", false);
            setFieldValue("residentProvider", 0);
            setResidentProviderValue(0);
            setResides(false);
          } else {
            setFieldValue("isResidentProvider", true);
            setFieldValue("residentProvider", 0);
            setResidentProviderValue(0);
            setResides(true);
          }
        };

        const handleIsHomeBased = (newValue) => {
          if (newValue.target.value === "false") {
            setFieldValue("homeBased", false);
          } else {
            setFieldValue("homeBased", true);
          }
        };

        const handleServiceLocation = (e, location) => {
          let newValue;
          const currentValue = values?.serviceLocation;
          if (e.target.checked) {
            if (currentValue && currentValue !== location) {
              newValue = "Both";
            } else {
              newValue = location;
            }
          } else {
            if (currentValue === "Both") {
              newValue = SERVICE_LOCATION.find((n) => n !== location);
            } else {
              newValue = "";
            }
          }
          if (newValue === "Premises") {
            setFieldValue("deliveryStatus", false);
          }
          handleChange({
            target: {
              name: "serviceLocation",
              value: newValue,
            },
          });
        };

        return (
          <Paper
            className="paper__container"
            sx={{ height: AUTO }}
            elevation={4}
          >
            <form noValidate onSubmit={handleSubmit} className="form">
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <Stack spacing={3}>
                    <Breadcrumbs
                      separator={<NavigateNextIcon />}
                      aria-label="breadcrumb"
                    >
                      {breadcrumbs}
                    </Breadcrumbs>
                  </Stack>
                </Grid>
                <Grid container spacing={2} marginLeft={0.5} marginTop={1}>
                  <Grid item xs={2}>
                    <FormControl
                      size={SMALL}
                      fullWidth
                      error={touched.namePrefix && Boolean(errors.namePrefix)}
                    >
                      <InputLabel id="name-prefix-label" required>
                        Name Prefix
                      </InputLabel>
                      <Select
                        labelId="name-prefix-label"
                        label="Name Prefix"
                        value={values?.namePrefix || ""}
                        onChange={handleChange}
                        name="namePrefix"
                        onBlur={handleBlur}
                      >
                        {NAME_PREFIX_TYPES.map((namePrefixType) => (
                          <MenuItem key={namePrefixType} value={namePrefixType}>
                            {namePrefixType}
                          </MenuItem>
                        ))}
                      </Select>
                      {touched.namePrefix && errors.namePrefix && (
                        <FormHelperText>{errors.namePrefix}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      label="Name Suffix"
                      fullWidth
                      size={SMALL}
                      value={values?.nameSuffix}
                      onChange={handleChange}
                      name="nameSuffix"
                    ></TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      required
                      label="First Name"
                      fullWidth
                      size={SMALL}
                      value={values?.firstName}
                      onChange={handleChange}
                      name="firstName"
                      onBlur={handleBlur}
                      error={touched.firstName && Boolean(errors.firstName)}
                      helperText={touched.firstName && errors.firstName}
                    ></TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      required
                      label="Last Name"
                      fullWidth
                      size={SMALL}
                      value={values?.lastName}
                      onChange={handleChange}
                      name="lastName"
                      onBlur={handleBlur}
                      error={touched.lastName && Boolean(errors.lastName)}
                      helperText={touched.lastName && errors.lastName}
                    ></TextField>
                  </Grid>
                </Grid>
                <Grid container spacing={2} marginLeft={0.5} marginTop={0.3}>
                  <Grid item xs={4}>
                    <PhoneAutocomplete
                      selectedPhoneData={selectedPhoneData}
                      handlePhoneNumberChange={handlePhoneNumberChange}
                      setSelectedPhoneData={setSelectedPhoneData}
                      phoneData={phoneData}
                      values={values}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      countryCodes={countryCodes}
                      onCountryCodeChanged={onCountryCodeChanged}
                      selectedCountryCode={selectedCountryCode}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Email"
                      fullWidth
                      size={SMALL}
                      value={values?.email}
                      onChange={handleChange}
                      name="email"
                      onBlur={handleBlur}
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                    ></TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      required
                      label="Title"
                      fullWidth
                      size={SMALL}
                      value={values?.title}
                      onChange={handleChange}
                      name="title"
                      onBlur={handleBlur}
                      error={touched.title && Boolean(errors.title)}
                      helperText={touched.title && errors.title}
                    ></TextField>
                  </Grid>
                </Grid>
                <Grid container spacing={2} marginLeft={0.5} marginTop={0.3}>
                  <Grid item xs={4}>
                    <Autocomplete
                      size="small"
                      disableCloseOnSelect
                      multiple
                      options={[
                        ...values?.serviceCategory,
                        ...serviceCategories
                          ?.map((serviceCategory) => serviceCategory.id)
                          ?.filter(
                            (option) =>
                              !values?.serviceCategory?.includes(option)
                          ),
                      ]}
                      getOptionLabel={(option) => {
                        const category = serviceCategories.find(
                          (s) => s.id === option
                        );
                        return category?.name || "";
                      }}
                      value={values?.serviceCategory}
                      onChange={(_, newValue) => {
                        setFieldValue("serviceCategory", newValue);
                      }}
                      onInputChange={(_, newValue) => {
                        setFieldValue("serviceCategory", newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Service Category"
                          name="serviceCategory"
                          fullWidth
                          size={SMALL}
                          value={values?.serviceCategory}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.serviceCategory &&
                            Boolean(errors.serviceCategory)
                          }
                          helperText={
                            touched.serviceCategory && errors.serviceCategory
                          }
                        />
                      )}
                      renderTags={(selected, getTagProps) => {
                        const displayedTags = selected.slice(0, 1);
                        const hiddenTagsCount =
                          selected.length - displayedTags.length;
                        return (
                          <Box display="flex" alignItems="center">
                            {displayedTags.map((value, index) => (
                              <Chip
                                size="small"
                                key={value}
                                label={
                                  serviceCategories.find(
                                    (serviceCategory) =>
                                      serviceCategory.id === value
                                  )?.name
                                }
                                {...getTagProps({ index })}
                              />
                            ))}
                            {hiddenTagsCount > 0 && (
                              <Box ml={1}>+{hiddenTagsCount}</Box>
                            )}
                          </Box>
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <BusinessNameAutocomplete
                      selectedBusinessNameData={selectedBusinessNameData}
                      handleBusinessNameChange={handleBusinessNameChange}
                      setSelectedBusinessNameData={setSelectedBusinessNameData}
                      businessNameData={businessNameData}
                      values={values}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl
                      size={SMALL}
                      fullWidth
                      error={
                        touched.preferredLanguage &&
                        Boolean(errors.preferredLanguage)
                      }
                    >
                      <InputLabel id="preferred-language-label" required>
                        Preferred Language
                      </InputLabel>
                      <Select
                        labelId="preferred-language-label"
                        label="Preferred Language"
                        value={values?.preferredLanguage || ""}
                        onChange={handleChange}
                        name="preferredLanguage"
                        onBlur={handleBlur}
                      >
                        {preferredLanguage.map((language) => (
                          <MenuItem key={language?.id} value={language?.id}>
                            {language?.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {touched.preferredLanguage &&
                        errors.preferredLanguage && (
                          <FormHelperText>
                            {errors.preferredLanguage}
                          </FormHelperText>
                        )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2} marginLeft={0.5} marginTop={0.3}>
                  <Grid item xs={4}>
                    <ImagePicker
                      from={SERVICE_PROVIDER_CREATE}
                      setFieldValue={setFieldValue}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                    />
                    {touched.image && errors.image && (
                      <Grid>
                        <FormHelperText sx={{ color: RED }}>
                          Image is required
                        </FormHelperText>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item md={8}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          label="Latitude"
                          placeholder="Enter Latitude"
                          fullWidth
                          type="number"
                          inputProps={{
                            step: "any",
                            min: -180,
                            max: 180,
                          }}
                          required
                          size={SMALL}
                          value={values?.latitude}
                          onChange={handleChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          name="latitude"
                          onBlur={handleBlur}
                          sx={{
                            "& input[type=number]": {
                              MozAppearance: "textfield",
                            },
                            "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                              {
                                WebkitAppearance: "none",
                                margin: 0,
                              },
                          }}
                          error={touched.latitude && Boolean(errors.latitude)}
                          helperText={touched.latitude && errors.latitude}
                        />
                      </Grid>

                      {/* Longitude */}
                      <Grid item xs={6}>
                        <TextField
                          label="Longitude"
                          placeholder="Enter Longitude"
                          fullWidth
                          type="number"
                          inputProps={{
                            step: "any",
                            min: -180,
                            max: 180,
                          }}
                          required
                          size={SMALL}
                          value={values?.longitude}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={handleChange}
                          name="longitude"
                          onBlur={handleBlur}
                          sx={{
                            "& input[type=number]": {
                              MozAppearance: "textfield",
                            },
                            "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                              {
                                WebkitAppearance: "none",
                                margin: 0,
                              },
                          }}
                          error={touched.longitude && Boolean(errors.longitude)}
                          helperText={touched.longitude && errors.longitude}
                        />
                      </Grid>

                      {/* Left column: Location URL and Set Business Hours (stacked) */}
                      <Grid
                        item
                        xs={6}
                        container
                        spacing={2}
                        direction="column"
                      >
                        <Grid item>
                          <TextField
                            label="Location URL"
                            placeholder="Enter Location URL"
                            fullWidth
                            required
                            value={values?.locationUrl || ""}
                            onChange={handleChange}
                            name="locationUrl"
                            onBlur={handleBlur}
                            size={SMALL}
                            error={
                              touched.locationUrl && Boolean(errors.locationUrl)
                            }
                            helperText={
                              touched.locationUrl && errors.locationUrl
                            }
                          />
                        </Grid>
                        <Grid item textAlign={"center"} width={"100%"}>
                          <Tooltip
                            title="Click to set your business hours"
                            arrow
                          >
                            <Button
                              onClick={operationalHoursModalHanldler}
                              variant="contained"
                              startIcon={<WorkHistoryOutlinedIcon />}
                              sx={{
                                textTransform: "capitalize",
                                bgcolor: "#003350",
                                color: "#fff",
                                "&:hover": {
                                  bgcolor: "#0056b3",
                                },
                                borderRadius: "8px",
                                padding: "8px 16px",
                                fontSize: "14px",
                                fontWeight: "500",
                                width: "100%",
                              }}
                            >
                              Set Business Hours
                            </Button>
                          </Tooltip>
                        </Grid>
                      </Grid>

                      {/* Right column: Address */}
                      <Grid item xs={6}>
                        <TextField
                          label="Address"
                          placeholder="Enter Address"
                          fullWidth
                          multiline
                          minRows={4}
                          maxRows={4}
                          required
                          value={values?.address || ""}
                          onChange={(e) => {
                            handleChange(e);
                            setFieldValue("address", e.target.value);
                          }}
                          name="address"
                          onBlur={handleBlur}
                          error={touched.address && Boolean(errors.address)}
                          helperText={touched.address && errors.address}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={2} marginLeft={0.5} marginTop={0.3}>
                  <Grid item md={4} marginTop={"16px"}>
                    <Autocomplete
                      size={SMALL}
                      multiple
                      disableCloseOnSelect
                      limitTags={1}
                      options={[
                        ...values.servicedTo,
                        ...residents
                          .map((r) => r.id)
                          .filter((id) => !values.servicedTo.includes(id)),
                      ]}
                      value={values?.servicedTo}
                      onChange={(_, newValue) => {
                        setFieldValue("servicedTo", newValue);
                      }}
                      getOptionLabel={(option) => {
                        const resident = residents?.find(
                          (r) => r.id === option
                        );
                        return `${resident?.name} | ${resident?.apartment?.building?.name} | ${resident?.apartment?.number}`;
                      }}
                      renderOption={(props, option) => {
                        const resident = residents?.find(
                          (r) => r.id === option
                        );
                        return (
                          <li {...props}>
                            <Grid container alignItems={CENTER} display={FLEX}>
                              <Grid
                                item
                                width={60}
                                height={60}
                                marginRight={"20px"}
                              >
                                <img
                                  src={
                                    resident?.image ? resident?.image : avatar
                                  }
                                  alt=""
                                  width="100%"
                                  height="100%"
                                />
                              </Grid>
                              <Grid item width="70%">
                                <Grid
                                  container
                                  alignItems={FLEX_START}
                                  display={FLEX}
                                  flexDirection={COLUMN}
                                >
                                  <Grid item width="100%">
                                    <Typography
                                      variant="body2"
                                      color={DEEP_OCEAN_BLUE}
                                      noWrap
                                      fontWeight={BOLD}
                                    >
                                      {resident?.name}
                                    </Typography>
                                  </Grid>
                                  <Grid item width="100%">
                                    <Typography variant="body2">
                                      {resident?.apartment?.building?.name}
                                    </Typography>
                                  </Grid>
                                  <Grid item width="100%">
                                    <Typography variant="body2">
                                      {resident?.apartment?.number}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </li>
                        );
                      }}
                      renderTags={(selected, getTagProps) => {
                        const displayedTags = selected?.slice(0, 1);
                        const hiddenTagsCount =
                          selected.length - displayedTags.length;
                        return (
                          <Box display={FLEX} alignItems={CENTER}>
                            {displayedTags.map((value, index) => (
                              <Chip
                                key={value}
                                label={
                                  residents?.find((r) => r.id === value)?.name +
                                  " | " +
                                  residents?.find((r) => r.id === value)
                                    ?.apartment?.building?.name +
                                  " | " +
                                  residents?.find((r) => r.id === value)
                                    ?.apartment?.number
                                }
                                {...getTagProps({ index })}
                              />
                            ))}
                            {hiddenTagsCount > 0 && (
                              <Box ml={1}>+{hiddenTagsCount}</Box>
                            )}
                          </Box>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Serviced To"
                          name="servicedTo"
                          error={
                            touched.servicedTo && Boolean(errors.servicedTo)
                          }
                          helperText={touched.servicedTo && errors.servicedTo}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={4} marginTop={"16px"}>
                    <Autocomplete
                      multiple
                      disableCloseOnSelect
                      limitTags={1}
                      options={[
                        // ...values.referredBy,
                        ...residents
                          .map((r) => r.id)
                          .filter((id) => !values.referredBy.includes(id)),
                      ]}
                      value={values?.referredBy}
                      onChange={(_, newValue) => {
                        handleReferredBy(newValue);
                      }}
                      getOptionLabel={(option) => {
                        const resident = residents?.find(
                          (r) => r.id === option
                        );
                        return `${resident?.name} | ${resident?.apartment?.building?.name} | ${resident?.apartment?.number}`;
                      }}
                      renderOption={(props, option) => {
                        const resident = residents?.find(
                          (r) => r.id === option
                        );
                        return (
                          <li {...props}>
                            <Grid container alignItems={CENTER} display={FLEX}>
                              <Grid
                                item
                                width={60}
                                height={60}
                                marginRight={"20px"}
                              >
                                <img
                                  src={
                                    resident?.image ? resident?.image : avatar
                                  }
                                  alt=""
                                  width="100%"
                                  height="100%"
                                />
                              </Grid>
                              <Grid item width="70%">
                                <Grid
                                  container
                                  alignItems={FLEX_START}
                                  display={FLEX}
                                  flexDirection={COLUMN}
                                >
                                  <Grid item width="100%">
                                    <Typography
                                      variant="body2"
                                      color={DEEP_OCEAN_BLUE}
                                      noWrap
                                      fontWeight={BOLD}
                                    >
                                      {resident?.name}
                                    </Typography>
                                  </Grid>
                                  <Grid item width="100%">
                                    <Typography variant="body2">
                                      {resident?.apartment?.building?.name}
                                    </Typography>
                                  </Grid>
                                  <Grid item width="100%">
                                    <Typography variant="body2">
                                      {resident?.apartment?.number}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </li>
                        );
                      }}
                      renderTags={(selected, getTagProps) => {
                        const displayedTags = selected?.slice(0, 1);
                        const hiddenTagsCount =
                          selected.length - displayedTags.length;
                        return (
                          <Box display={FLEX} alignItems={CENTER}>
                            {displayedTags?.map((value, index) => (
                              <Chip
                                key={value}
                                label={
                                  residents?.find((r) => r.id === value)?.name +
                                  " | " +
                                  residents?.find((r) => r.id === value)
                                    ?.apartment?.building?.name +
                                  " | " +
                                  residents?.find((r) => r.id === value)
                                    ?.apartment?.number
                                }
                                {...getTagProps({ index })}
                              />
                            ))}
                            {hiddenTagsCount > 0 && (
                              <Box ml={1}>+{hiddenTagsCount}</Box>
                            )}
                          </Box>
                        );
                      }}
                      size={SMALL}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Referred By"
                          name="referredBy"
                          error={
                            touched.referredBy && Boolean(errors.referredBy)
                          }
                          helperText={touched.referredBy && errors.referredBy}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={4} marginTop={"16px"}>
                    <Autocomplete
                      disabled={!values.isResidentProvider}
                      options={residents
                        .map((r) => r.id)
                        .filter((id) => values.residentProvider !== id)}
                      value={
                        values?.residentProvider !== 0
                          ? values.residentProvider
                          : null
                      }
                      onChange={(_, newValue) => {
                        handleSelectResidentProvider(newValue);
                      }}
                      getOptionLabel={(option) => {
                        const resident = residents?.find(
                          (r) => r.id === option
                        );
                        return `${resident?.name} | ${resident?.apartment?.building?.name} | ${resident?.apartment?.number}`;
                      }}
                      renderOption={(props, option) => {
                        const resident = residents?.find(
                          (r) => r.id === option
                        );
                        return (
                          <li {...props}>
                            <Grid container alignItems="center" display="flex">
                              <Grid
                                item
                                width={60}
                                height={60}
                                marginRight={"20px"}
                              >
                                <img
                                  src={
                                    resident?.image ? resident?.image : avatar
                                  }
                                  alt=""
                                  width="100%"
                                  height="100%"
                                />
                              </Grid>
                              <Grid item width="70%">
                                <Grid
                                  container
                                  alignItems="flex-start"
                                  display="flex"
                                  flexDirection="column"
                                >
                                  <Grid item width="100%">
                                    <Typography
                                      variant="body2"
                                      color={DEEP_OCEAN_BLUE}
                                      noWrap
                                      fontWeight="bold"
                                    >
                                      {resident?.name}
                                    </Typography>
                                  </Grid>
                                  <Grid item width="100%">
                                    <Typography variant="body2">
                                      {resident?.apartment?.building?.name}
                                    </Typography>
                                  </Grid>
                                  <Grid item width="100%">
                                    <Typography variant="body2">
                                      {resident?.apartment?.number}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </li>
                        );
                      }}
                      renderTags={(selected) => {
                        const resident = residents?.find(
                          (r) => r.id === selected
                        );
                        return (
                          <Chip
                            label={
                              <span className="autocomplete-single">
                                {`${resident?.name} | ${resident?.apartment?.building?.name} | ${resident?.apartment?.number}`}
                              </span>
                            }
                          />
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          disabled={!values.isResidentProvider}
                          {...params}
                          label="Resident"
                          name="residentProvider"
                          error={
                            values.isResidentProvider &&
                            touched.residentProvider &&
                            Boolean(errors.residentProvider)
                          }
                          helperText={
                            values.isResidentProvider &&
                            touched.residentProvider &&
                            errors.residentProvider
                          }
                        />
                      )}
                      size="small"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} marginLeft={1} marginTop={0.3}>
                  <Grid item xs={3}>
                    <FormControl>
                      <FormControlLabel
                        key={"referAnonymously"}
                        control={
                          <Checkbox
                            sx={{
                              color: DEEP_OCEAN_BLUE,
                            }}
                            disabled={!values?.referredBy?.length > 0}
                            size={SMALL}
                            name="referAnonymously"
                            checked={values?.referAnonymously}
                            onChange={(value) =>
                              handleReferralHelper(value, "referAnonymously")
                            }
                          />
                        }
                        label={<Typography>Refer Anonymously</Typography>}
                      />
                    </FormControl>
                  </Grid>
                  <Grid xs={3} marginTop={2}>
                    <FormControl>
                      <FormControlLabel
                        key={"referrerAvailedService"}
                        control={
                          <Checkbox
                            sx={{
                              color: DEEP_OCEAN_BLUE,
                            }}
                            disabled={!values?.referredBy?.length > 0}
                            size={SMALL}
                            name="referrerAvailedService"
                            checked={values?.referrerAvailedService}
                            onChange={(value) =>
                              handleReferralHelper(
                                value,
                                "referrerAvailedService"
                              )
                            }
                          />
                        }
                        label={
                          <Typography>Referrer Availed Service</Typography>
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      label="Service Start Price"
                      placeholder="Enter the service starting price."
                      fullWidth
                      type="number"
                      size={SMALL}
                      value={values?.serviceStartPrice}
                      onChange={(e) => {
                        handleChange(e);
                        validatePrices(e.target.name, e.target.value);
                      }}
                      name="serviceStartPrice"
                      onBlur={handleBlur}
                      error={!!priceErrors?.serviceStartPrice}
                      helperText={priceErrors?.serviceStartPrice}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{
                        "& input[type=number]": {
                          MozAppearance: "textfield",
                        },
                        "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                          {
                            WebkitAppearance: "none",
                            margin: 0,
                          },
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      label="Service End Price"
                      placeholder="Enter the service ending price."
                      fullWidth
                      type="number"
                      size={SMALL}
                      value={values?.serviceEndPrice}
                      onChange={(e) => {
                        handleChange(e);
                        validatePrices(e.target.name, e.target.value);
                      }}
                      name="serviceEndPrice"
                      onBlur={handleBlur}
                      error={!!priceErrors?.serviceEndPrice}
                      helperText={priceErrors?.serviceEndPrice}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{
                        "& input[type=number]": {
                          MozAppearance: "textfield",
                        },
                        "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                          {
                            WebkitAppearance: "none",
                            margin: 0,
                          },
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} marginLeft={1} marginTop={0.3}>
                  <Grid item md={6} marginBottom={10}>
                    <TextField
                      required
                      label="Description"
                      fullWidth
                      size={SMALL}
                      value={values.description || ""}
                      onChange={handleChange}
                      name="description"
                      multiline
                      minRows={5}
                      maxRows={5}
                      onBlur={handleBlur}
                      error={touched.description && Boolean(errors.description)}
                      helperText={touched.description && errors.description}
                    ></TextField>
                  </Grid>
                  <Grid item md={6} marginBottom={10}>
                    <TextField
                      label="Private Notes"
                      fullWidth
                      size={SMALL}
                      value={values.privateNotes || ""}
                      onChange={handleChange}
                      name="privateNotes"
                      multiline
                      minRows={5}
                      maxRows={5}
                    ></TextField>
                  </Grid>
                </Grid>
                <Grid container spacing={2} marginLeft={1} marginTop={0.3}>
                  {/* Service Location Section */}
                  <Grid item xs={2} marginTop={3}>
                    <Typography
                      color={TEXT_COLOR}
                      fontSize="1rem"
                      fontWeight="400"
                      paddingRight="10px"
                    >
                      Service Location
                      <span className="MuiFormLabel-asterisk"> *</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={3} marginTop={1} alignContent={"start"}>
                    <Grid container>
                      {SERVICE_LOCATION.map((location) => (
                        <Grid item xs={6} key={location} marginTop={1}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                sx={{ color: DEEP_OCEAN_BLUE }}
                                size={SMALL}
                                value={values?.serviceLocation}
                                checked={
                                  values?.serviceLocation === location ||
                                  values?.serviceLocation === "Both"
                                }
                                onChange={(e) =>
                                  handleServiceLocation(e, location)
                                }
                                onBlur={handleBlur}
                              />
                            }
                            label={<Typography>{location}</Typography>}
                          />
                        </Grid>
                      ))}
                    </Grid>
                    {touched.serviceLocation && errors.serviceLocation && (
                      <Grid item xs={6}>
                        <FormHelperText style={{ color: RED }}>
                          {errors.serviceLocation}
                        </FormHelperText>
                      </Grid>
                    )}
                  </Grid>
                  {/* Pickup & Drop Services */}
                  {values?.serviceLocation &&
                    (values?.serviceLocation === "Home" ||
                      values?.serviceLocation === "Both") && (
                      <Grid item xs={5} marginTop={2}>
                        <Grid container>
                          <Grid item xs={5} marginTop={1}>
                            <Typography
                              fontSize="1rem"
                              fontWeight="400"
                              paddingBottom="8px"
                            >
                              Pickup & Drop Services
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Switch
                              name="deliveryStatus"
                              checked={values?.deliveryStatus}
                              onChange={handleChange}
                              disabled={
                                !values?.serviceLocation ||
                                values?.serviceLocation === "Premises"
                              }
                            />
                          </Grid>
                          <Grid item xs={4} marginTop={1}>
                            <Typography
                              fontSize="1rem"
                              fontWeight="400"
                              paddingBottom="8px"
                            >
                              Home visit
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                </Grid>
                <Grid container spacing={2} marginLeft={1} marginTop={0.3}>
                  {/* Resides in the Community */}
                  {/* <Grid
                        item
                        xs={4}
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        // spacing={1}
                      > */}
                  {/* <Grid container spacing={2}> */}
                  <Grid item xs={2} marginTop={1}>
                    <Typography>Resides in the Community</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl>
                      <RadioGroup
                        row
                        name="isResidentProvider"
                        onChange={handleIsSelectResidentProvider}
                      >
                        <FormControlLabel
                          value={true}
                          checked={values?.isResidentProvider}
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value={false}
                          checked={!values?.isResidentProvider}
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  {/* </Grid> */}

                  {/* Home Based Services */}
                  {resides && (
                    <Grid
                      item
                      xs={5}
                      // alignItems="center"
                      justifyContent="space-between"
                      // spacing={1}
                      marginTop={1}
                    >
                      <Grid container>
                        <Grid item xs={5} marginTop={1}>
                          <Typography>Home Based Services</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl>
                            <RadioGroup
                              row
                              name="homeBased"
                              onChange={handleIsHomeBased}
                            >
                              <FormControlLabel
                                value={true}
                                checked={resides && values?.homeBased}
                                control={<Radio />}
                                label="Yes"
                                disabled={!resides}
                              />
                              <FormControlLabel
                                value={false}
                                checked={resides && !values?.homeBased}
                                control={<Radio />}
                                label="No"
                                disabled={!resides}
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {/* </Grid> */}
                </Grid>
                <Grid>
                <ScheduleFormModal onCancelHandler={operationalHoursModalCloseHandler}   
                    onOkHandler={(scheduleData) => {
                      setSpBusinessHours(scheduleData);
                      setFieldValue("businessHours", scheduleData); 
                      operationalHoursModalCloseHandler();
                    }} 
                    formValues={values.businessHours}
                    />
                 
                </Grid>
                <Grid item xs={6} marginLeft={3}>
                  {values.isResidentProvider &&
                    (residentProviderValue === null ||
                      residentProviderValue === 0) && (
                      <span className="form-note-label">
                        Note: Please select a resident to proceed.
                      </span>
                    )}
                </Grid>
                <Grid item md={12} display={FLEX} justifyContent={CENTER}>
                  <Button
                    type="submit"
                    variant="contained"
                    className="submit__button"
                    disabled={
                      Object.keys(priceErrors).length > 0 ||
                      (values.isResidentProvider &&
                        (residentProviderValue === null ||
                          residentProviderValue === 0))
                    }
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
              <Loader />
            </form>
            <Snackbar
              open={alertOpen}
              autoHideDuration={6000}
              onClose={handleAlertClose}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Alert onClose={handleAlertClose} severity="error">
                {alertContent}
              </Alert>
            </Snackbar>
          </Paper>
        );
      }}
    </Formik>
  );
};

export default Create;
