import {
  Badge,
  IconButton,
  Rating,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableSortLabel,
  TablePagination,
  TableRow,
  Tooltip,
  Grid,
  Alert,
  Snackbar,
} from "@mui/material";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import FlagIcon from "@mui/icons-material/Flag";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import RecyclingIcon from "@mui/icons-material/Recycling";
import StarIcon from "@mui/icons-material/Star";
import sortInactiveIcon from "../../assets/images/sort_grey.png";
import sortAscIcon from "../../assets/images/asc_sort.png";
import sortDscIcon from "../../assets/images/dsc_sort.png";
import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";
import "./Common.scss";
import {
  CENTER,
  DEEP_OCEAN_BLUE,
  TOP,
  WHITE,
  RED,
  ROW,
  NO_WRAP,
  BUY_SELL,
  RESIDENT_LIST,
  REVIEW_LIST,
  SERVICE_PROVIDER_LIST,
  GOLD,
} from "../../utils/constants";
import { useState } from "react";

const DataTable = (props) => {
  const noBreakheaders = ["Phone Number", "Business Name"];

  const RatingStars = ({ value }) => {
    return (
      <Rating
        name="rating-stars"
        value={value}
        precision={0.5} // allow half star increments
        readOnly // make the rating read-only
      />
    );
  };
  
  const {
    from,
    headers,
    data,
    onView,
    onEdit,
    onDelete,
    sortOrderHandler,
    totalRecords,
    rowsPerPage,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    handleClick,
    handleViewClick,
    sortBy,
    sortColumn,
    classes,
  } = props;

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertContent, setAlertContent] = useState("");

  const handleButtonClick = (value, type) => {
    if (from === RESIDENT_LIST) {
      if (type === "delete") {
        if (value.deleted && value.phone === null) {
          setAlertContent(
            "Action blocked: The user has been deleted from the system."
          );
          setAlertOpen(true);
        } else {
          onDelete(value);
        }
      }
      if (type === "view") {
        if (value?.deleted) {
          setAlertContent(
            "Action blocked: The user has been deleted from the system."
          );
          setAlertOpen(true);
        } else if (
          value?.residentStatus?.name === "Phone number verified" ||
          value?.residentStatus?.name === "Awaiting phone OTP"
        ) {
          setAlertContent(
            "Action blocked: The user is yet to provide details."
          );
          setAlertOpen(true);
        } else {
          onView(value);
        }
      }
      if (type === "edit") {
        if (value?.deleted) {
          setAlertContent(
            "Action blocked: The user has been deleted from the system."
          );
          setAlertOpen(true);
        } else if (
          value?.residentStatus?.name === "Phone number verified" ||
          value?.residentStatus?.name === "Awaiting phone OTP"
        ) {
          setAlertContent(
            "Action blocked: The user is yet to provide details."
          );
          setAlertOpen(true);
        } else {
          onEdit(value);
        }
      }
    }
    if (from === BUY_SELL) {
      if (type === "delete") {
        if (value.buySellItem.resident.deleted) {
          setAlertContent(
            "Action blocked: The user has been deleted from the system."
          );
          setAlertOpen(true);
        } else {
          onDelete(value);
        }
      }
      if (type === "view") {
        onView(value);
      }
      if (type === "edit") {
        if (value.buySellItem.resident.deleted) {
          setAlertContent(
            "Action blocked: The user has been deleted from the system."
          );
          setAlertOpen(true);
        } else {
          onEdit(value);
        }
      }
    }
    if (from === REVIEW_LIST) {
      if (type === "delete") {
        onDelete(value);
      }
      if (type === "view") {
        onView(value);
      }
      if (type === "edit") {
        onEdit(value);
      }
    }
    if (from === SERVICE_PROVIDER_LIST) {
      if (type === "delete") {
        onDelete(value);
      }
      if (type === "view") {
        onView(value);
      }
      if (type === "edit") {
        onEdit(value);
      }
    }
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
    setAlertContent("");
  };

  return (
    <div
      style={{
        position: "relative",
        overflowX: "scroll",
        scrollbarWidth: "thin",
        WebkitOverflowScrolling: "touch",
      }}
    >
      <Table className="TableComp__table">
        <TableHead>
          <TableRow sx={{ whiteSpace: NO_WRAP }}>
            {headers.map(
              (header) =>
                header !== null && (
                  <TableCell
                    key={header?.label}
                    className={
                      header?.label === "Actions"
                        ? "TableComp__table__header__Actions"
                        : noBreakheaders.includes(header?.label)
                        ? "No_Break_TableComp__table__header"
                        : "TableComp__table__header"
                    }
                    width={
                      header?.width ? header?.width : `${100 / headers.length}%`
                    }
                    sx={{
                      ...(header.label === "Actions" && {
                        position: "sticky",
                        right: 0,
                        backgroundColor: "white",
                        zIndex: 1,
                      }),
                      "&:focus": {
                        outline: "2px solid blue", // Visible focus for accessibility
                      },
                    }}
                  >
                    {header?.label !== "Actions" &&
                    header?.label !== "Status" &&
                    header?.label !== "Description" &&
                    header?.label !== "Condition" &&
                    header?.label !== "Delivery Type" &&
                    header?.label !== "Free" &&
                    (header?.label !== "Category" ||
                      from !== SERVICE_PROVIDER_LIST) ? (
                      <TableSortLabel
                        active={
                          header?.sortKey
                            ? sortColumn === header?.sortKey
                            : sortColumn === header?.label
                        }
                        direction={
                          sortColumn === header?.label ||
                          sortColumn === header?.sortKey
                            ? sortBy
                            : "asc"
                        }
                        onClick={() =>
                          sortOrderHandler(
                            header?.sortKey ? header?.sortKey : header?.label
                          )
                        }
                        IconComponent={() => (
                          <img
                            src={
                              (sortColumn === header?.label ||
                                sortColumn === header?.sortKey) &&
                              sortBy === "asc"
                                ? sortAscIcon
                                : (sortColumn === header?.label ||
                                    sortColumn === header?.sortKey) &&
                                  sortBy === "desc"
                                ? sortDscIcon
                                : sortInactiveIcon
                            }
                            alt="Sort icon"
                            width={10}
                            height={10}
                          />
                        )}
                      >
                        {header?.label}
                      </TableSortLabel>
                    ) : (
                      header?.label
                    )}
                  </TableCell>
                )
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {!data?.length ? (
            <TableRow>
              <TableCell align={CENTER} colSpan={headers?.length}>
                No records to show
              </TableCell>
            </TableRow>
          ) : (
            data?.map((row, i) => (
              <TableRow key={i}>
                {row?.commonColumns.map((data, index) => {
                  return (
                    data !== null && (
                      <TableCell key={index} component="th" scope={ROW}>
                        <div
                          className={`${
                            index === 1
                              ? "Service_Provider_phone_number_TableComp__table__cell__Content"
                              : "TableComp__table__cell__Content"
                          }`}
                        >
                          <Tooltip title={data} placement={TOP} arrow>
                            <div
                              className={`desc-data ${
                                index <= 1 ? classes : ""
                              }`}
                              onClick={() =>
                                from === REVIEW_LIST
                                  ? handleViewClick(index, row)
                                  : ""
                              }
                            >
                              {data}
                            </div>
                          </Tooltip>
                          {from === REVIEW_LIST && row?.flag && index === 0 && (
                            <IconButton
                              onClick={(event) => handleClick(row?.data, event)}
                            >
                              <FlagIcon
                                sx={{
                                  color: RED,
                                }}
                              />
                            </IconButton>
                          )}
                          <Grid>
                            {from === SERVICE_PROVIDER_LIST &&
                              row?.data?.serviceProviderFlags.length >= 1 &&
                              index === 3 && (
                                <IconButton
                                  sx={{ marginRight: 5 }}
                                  onClick={(event) =>
                                    handleClick(row?.data, event)
                                  }
                                >
                                  <FlagIcon
                                    sx={{
                                      color: RED,
                                    }}
                                  />
                                </IconButton>
                              )}
                          </Grid>
                          <Grid>
                            {from === SERVICE_PROVIDER_LIST &&
                              index === 8 &&
                              row?.data?.rating !== "0" &&
                              row?.data?.rating !== "0.0" && (
                                <StarIcon
                                  sx={{ color: GOLD, marginRight: 7 }}
                                />
                              )}
                          </Grid>
                          {index === 0 &&
                            from === BUY_SELL &&
                            row?.data?.buySellItem?.sold && (
                              <Badge
                                badgeContent={"S"}
                                sx={{ marginLeft: "3px" }}
                              ></Badge>
                            )}
                          {from === BUY_SELL &&
                            row?.data?.buySellItemFlagsCount > 0 &&
                            index === 0 && (
                              <IconButton
                                onClick={(event) =>
                                  handleClick(row?.data, event)
                                }
                                sx={{ marginLeft: "3px", marginBottom: "3px" }}
                              >
                                <FlagIcon
                                  sx={{
                                    color: RED,
                                  }}
                                />
                              </IconButton>
                            )}
                          {index === 1 &&
                            from === REVIEW_LIST &&
                            row?.data?.anonymous && (
                              <Badge
                                badgeContent={"A"}
                                style={{ paddingLeft: "0.2rem" }}
                              ></Badge>
                            )}
                          {index === 2 &&
                            from === REVIEW_LIST &&
                            row?.data?.modifiedByAdmin && (
                              <Badge
                                badgeContent={"M"}
                                sx={{
                                  "& .MuiBadge-badge": {
                                    padding: "0 4px",
                                  },
                                }}
                              ></Badge>
                            )}
                        </div>
                      </TableCell>
                    )
                  );
                })}
                {from === RESIDENT_LIST && (
                  <TableCell>
                    <Badge
                      badgeContent={row?.residentStatus}
                      className="TableComp__table__cell__badge"
                      sx={{
                        "& .MuiBadge-badge": {
                          color: WHITE,
                          backgroundColor: DEEP_OCEAN_BLUE,
                        },
                      }}
                    />
                  </TableCell>
                )}
                {from === REVIEW_LIST && (
                  <TableCell>
                    <RatingStars value={row?.ratingValue} />
                  </TableCell>
                )}
                <TableCell
                  sx={{
                    position: "sticky",
                    right: 0,
                    backgroundColor: "white",
                    zIndex: 1,
                    display: "flex",
                    justifyContent: "flex-start",
                    // gap: 1,
                  }}
                >
                  <IconButton
                    // disabled={
                    //   row?.residentStatus === "Phone number verified" ||
                    //   row?.residentStatus === "Awaiting phone OTP" ||
                    //   (from === RESIDENT_LIST &&
                    //     row?.data?.deleted &&
                    //     row?.data?.phone === null)
                    // }
                    onClick={() => handleButtonClick(row?.data, "view")}
                  >
                    <Tooltip title={"View"} placement={TOP} arrow>
                      <FindInPageIcon className="TableComp__table__icon" />
                    </Tooltip>
                  </IconButton>
                  <IconButton
                    // disabled={
                    //   row?.residentStatus === "Phone number verified" ||
                    //   row?.residentStatus === "Awaiting phone OTP" ||
                    //   row?.data?.deleted ||
                    //   row?.data?.buySellItem?.deleted
                    // }
                    onClick={() => handleButtonClick(row?.data, "edit")}
                  >
                    <Tooltip title={"Edit"} placement={TOP} arrow>
                      <EditIcon className="TableComp__table__icon" />
                    </Tooltip>
                  </IconButton>
                  {from === REVIEW_LIST && row?.data?.deleted ? (
                    ""
                  ) : (
                    <div
                      onClick={(e) => e.stopPropagation()} // Prevent parent clicks
                      style={{
                        display: "inline-block",
                        cursor:
                          from === "RESIDENT_LIST" &&
                          row?.data?.deleted &&
                          row?.data?.phone === null
                            ? "not-allowed"
                            : "pointer",
                      }}
                    >
                      <IconButton
                        onClick={() => handleButtonClick(row?.data, "delete")}
                      >
                        {(from === RESIDENT_LIST && row?.data?.deleted) ||
                        (from === SERVICE_PROVIDER_LIST &&
                          row?.data?.serviceProvider?.deleted) ||
                        (from === BUY_SELL &&
                          row?.data?.buySellItem?.deleted) ? (
                          <Tooltip title={"Reinstate"} placement={TOP} arrow>
                            <RecyclingIcon className="TableComp__table__icon" />
                          </Tooltip>
                        ) : (
                          <Tooltip
                            title={
                              from === SERVICE_PROVIDER_LIST ||
                              from === RESIDENT_LIST
                                ? "Suspend"
                                : "Delete"
                            }
                            placement={TOP}
                            arrow
                          >
                            {from === SERVICE_PROVIDER_LIST ? (
                              <PauseCircleFilledIcon className="TableComp__table__icon" />
                            ) : (
                              <DeleteIcon className="TableComp__table__icon" />
                            )}
                          </Tooltip>
                        )}
                      </IconButton>
                    </div>
                  )}
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleAlertClose} severity="error">
          {alertContent}
        </Alert>
      </Snackbar>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default DataTable;
