import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useState } from "react";

import ModalView from "./ModalView";
import {
  BOLD,
  CENTER,
  COLUMN,
  DEEP_OCEAN_BLUE,
  FLEX,
  FLEX_START,
  SMALL,
  SPACE_BETWEEN,
} from "../../utils/constants";

const BusinessNameAutocomplete = (props) => {
  const {
    selectedBusinessNameData,
    handleBusinessNameChange,
    setSelectedBusinessNameData,
    businessNameData,
    values,
    handleChange,
    handleBlur,
    setFieldValue,
    touched,
    errors,
  } = props;

  const [viewModal, setViewModal] = useState(false);
  const [serviceProvider, setServiceProvider] = useState(null);

  const viewModalHandler = (e, option) => {
    e.stopPropagation();
    setServiceProvider(option);
    setViewModal(true);
  };

  return (
    <>
      <Autocomplete
        freeSolo
        inputValue={values?.businessName}
        value={selectedBusinessNameData}
        onInputChange={(_, newValue) => {
          setFieldValue("businessName", newValue);
          handleBusinessNameChange(newValue);
        }}
        onChange={(_, newValue) => setSelectedBusinessNameData(newValue)}
        options={businessNameData}
        getOptionLabel={(option) => option?.serviceProvider?.businessName}
        renderOption={(props, option) => {
          return (
            <li {...props}>
              <Grid
                container
                alignItems={CENTER}
                display={FLEX}
                justifyContent={SPACE_BETWEEN}
              >
                <Grid item width={60} height={60}>
                  <img src={option?.image} alt="" width="100%" height="100%" />
                </Grid>
                <Grid item width={"70%"}>
                  <Grid
                    container
                    alignItems={FLEX_START}
                    display={FLEX}
                    flexDirection={COLUMN}
                  >
                    <Grid item width={"100%"}>
                      <Typography
                        variant="body2"
                        color={DEEP_OCEAN_BLUE}
                        noWrap
                        textOverflow={"ellipsis"}
                        fontWeight={BOLD}
                      >
                        {`${option?.serviceProvider?.firstName} ${option?.serviceProvider?.lastName}`}
                      </Typography>
                    </Grid>
                    <Grid item width={"100%"}>
                      <Typography variant="body2">
                        {option?.serviceProvider?.phone}
                      </Typography>
                    </Grid>
                    <Grid item width={"100%"}>
                      <Typography variant="body2">
                        {option?.serviceProvider?.businessName}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <VisibilityIcon
                    fontSize={SMALL}
                    sx={{ color: DEEP_OCEAN_BLUE }}
                    onClick={(e) => {
                      viewModalHandler(e, option);
                    }}
                  />
                </Grid>
              </Grid>
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            className="custom-label"
            label="Business Name"
            fullWidth
            size={SMALL}
            value={values?.businessName}
            onChange={handleChange}
            name="businessName"
            onBlur={handleBlur}
            error={touched.businessName && Boolean(errors.businessName)}
            helperText={touched.businessName && errors.businessName}
          />
        )}
      />
      <ModalView
        viewModal={viewModal}
        setViewModal={setViewModal}
        serviceProvider={serviceProvider}
      />
    </>
  );
};

export default BusinessNameAutocomplete;
