import * as Yup from "yup";
import CommonValidations from "../Common/CommonValidations";

const Validator = (phoneData, phoneError) =>
  Yup.object({
    namePrefix: Yup.string().required("Name prefix is required"),
    phone: Yup.string()
      .required("Phone number is required")
      .matches(/^\d{10}$/, "Phone number must be 10 digits")
      .test("phone-exists", "Service provider already exist", function (value) {
        return !phoneData.length > 0;
      })
      .test("phone-exists", "Phone number already exist", function (value) {
        return !phoneError;
      }),
    email: Yup.string()
      .email("Invalid email format")
      .max(50, "Email must be at most 50 characters")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid email format"
      ),
    title: Yup.string()
      .required("Title is required")
      .max(30, "Title must be at most 30 characters"),
    businessName: Yup.string().max(
      30,
      "Business name must be at most 30 characters"
    ),
    serviceCategory: Yup.array().min(
      1,
      "At least one service category is required"
    ),
    preferredLanguage: Yup.string().required("Preferred language is required"),
    address: Yup.string().required("Address is required"),
    image: Yup.string().required("Image is required"),
    latitude: Yup.number().required("Latitude is required"),
    longitude: Yup.number().required("Longitude is required"),
    locationUrl: Yup.string().required("Location is required"),
    description: Yup.string()
      .required("Description is required")
      .max(1000, "Description must be at most 1000 characters"),
    serviceLocation: Yup.string().required("Service location is required"),
    residentProvider: Yup.number().required(
      "Please select a resident, if the provider is resident"
    ),
  }).concat(CommonValidations);

export default Validator;
